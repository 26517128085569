#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-image: url('../public/Background-portfoilo.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: space-between;
  /* overflow: hidden; */
}

body {
  height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left {
  z-index: -1;
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #aacebc36;
}

.right {
  z-index: -1;
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  background-color: #ba64ed29;
}